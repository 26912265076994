import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import SttHeading from '@stt-componentes/core/dist/lib/SttTypography/SttHeading';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { connect } from 'react-redux';
import { SttText } from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        width: '95%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    }
}));

const Erro401 = (props) => {
    const classes = useStyles();
    const { message } = props;

    return (
        <div className={classes.root}>
            <SttHeading variant="h1">
                <ErrorOutlineIcon />
                Não autorizado
            </SttHeading>
            <SttText>Por favor, tente reconectar no sistema. Para isso você deverá clicar no ícone que está no canto superior direito da tela e depois no botão "Sair".</SttText>
            <SttText>
                Em seguida, limpe a cache de dados do seu navegador.
                Para saber como limpar a cache no Firefox, <a target="_blank" href='https://support.mozilla.org/pt-BR/kb/como-limpar-cache-firefox'>clique aqui</a>.
                Para saber como limpar a cache no Google Chrome, <a target="_blank" href="https://support.google.com/accounts/answer/32050?hl=pt-BR&co=GENIE.Platform%3DDesktop">clique aqui</a>.
            </SttText>
            <SttText>Por fim, entre novamente no sistema com o mesmo usuário e senha que você utiliza normalmente.</SttText>
            <SttText>Se o erro persistir, entre em contato com o seu suporte.</SttText>
            <SttHeading variant="h4">
                {message}
            </SttHeading>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        message: state.index.message,
    };
};

export default connect(mapStateToProps, null)(Erro401);
