import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from 'react-redux';
import axios from 'axios';
import { getHeaders } from '../../../request';
import { PERMISSOES } from '../../../common/Constants';
import {
    SttButton,
    SttExpansionPanel,
    SttTextItem,
    SttHeading,
    SttContainer,
    SttCircularProgress,
    SttTranslateHook
} from '@stt-componentes/core';
import EnvioImagens from '../index';
import { temPermissaoRBAC } from '../../../secutity/acl';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    conteinerLesao: {
        marginBottom: theme.spacing(1)
    }
}));

const Detalhes = ({ id, voltar }) => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const user = useSelector(state => state.index.user);

    const [idComponenteEnvioImagens, setIdComponenteEnvioImagens] = useState(Math.random());
    const [solicitacao, setSolicitacao] = useState(null);
    const [dadosEnvioImagens, setDadosEnvioImagens] = useState([]);
    const [mostrarModalEnvioImagens, setMostrarModalEnvioImagens] = useState(false);
    const [antecedentes, setAntecedentes] = useState([]);

    useEffect(() => {
        if (id) {
            axios.get(`${global.gConfig.url_base_eeg}/solicitacao/${id}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        const { data } = response.data;
                        setSolicitacao(data);

                        setDadosEnvioImagens({
                            id: data.id_solicitacao,
                            idIndicacaoEeg: data.id_indicacao_eeg,
                            instituicao: data.id_instituicao,
                            resultadoExames: data.resultado_exames_anteriores
                        });

                        setAntecedentes(data.antecedentes.map(c => c.descricao));
                    }
                })
                .catch(err => console.log(err));
        }
    }, [id]);

    const handleAbrirModalEnvioImagens = () => {
        setIdComponenteEnvioImagens(Math.random());
        setMostrarModalEnvioImagens(true);
    }

    const callbackEnvioImagens = () => {
        voltar(true);
    }

    return (
        <SttContainer>
            <SttButton
                type="button"
                variant="outlined"
                color="primary"
                onClick={voltar}
                nomarginleft="true"
            >
                {strings.voltar}
            </SttButton>
            {
                !solicitacao
                    ?
                    <div className={classes.carregando}>
                        <SttCircularProgress color="primary" />
                    </div>
                    :
                    <>
                        <SttHeading variant="h1" color="primary" align="center">{strings.solicitacao}</SttHeading>
                        <SttExpansionPanel
                            title={strings.dadosGerais}
                            children={
                                <div>
                                    <SttTextItem key="1" title={strings.modalidade} content={solicitacao.modalidade} />
                                    <SttTextItem key="2" title={strings.dataSolicitacao} content={`${solicitacao.data_solicitacao_formatada} ${global.gConfig.sufixo_data_hora}`} />
                                    <SttTextItem key="3" title={strings.solicitante} content={solicitacao.nome_solicitante} />
                                    <SttTextItem key="4" title={strings.cidadeUf} content={`${solicitacao.cidade} / ${solicitacao.uf}`} />
                                </div>
                            }
                        />

                        <SttExpansionPanel
                            title={strings.dadosPaciente}
                            children={
                                <div>
                                    <SttTextItem key="5" title={strings.nome} content={solicitacao.nome_paciente} />
                                    <SttTextItem key="6" title={strings.dataNascimento} content={solicitacao.data_nascimento_paciente} />
                                    <SttTextItem key="7" title={strings.idade} content={`${solicitacao.idade_paciente} anos`} />
                                    <SttTextItem key="8" title={strings.genero} content={solicitacao.sexo_paciente} />
                                    <SttTextItem key="9" title={strings.cns} content={solicitacao.cartao_sus} />
                                    <SttTextItem key="10" title={strings.peso} content={solicitacao.peso_paciente} />
                                    <SttTextItem key="11" title={strings.altura} content={solicitacao.altura_paciente} />
                                </div>
                            }
                        />

                        <SttExpansionPanel
                            title={strings.indicacaoClinica}
                            children={
                                <div>
                                    {antecedentes.length > 0 && <SttTextItem key="20" title={strings.antecedentes} content={antecedentes.join(', ')} />}
                                    {
                                        solicitacao.indicacao_exame &&
                                        <SttTextItem key="15" title={strings.indicacaoExame} content={solicitacao.indicacao_exame} />
                                    }
                                    {
                                        solicitacao.resumo_historia_clinica &&
                                        <SttTextItem key="16" title={strings.resumoHistoria} content={solicitacao.resumo_historia_clinica} />
                                    }
                                    {
                                        solicitacao.medicamentos_em_uso &&
                                        <SttTextItem key="19" title={strings.medicamentosUso} content={solicitacao.medicamentos_em_uso} />
                                    }
                                    {
                                        solicitacao.resultado_exames_anteriores &&
                                        <SttTextItem key="17" title={strings.resultadoExames} content={solicitacao.resultado_exames_anteriores} />
                                    }
                                </div>
                            }
                        />
                        {
                            temPermissaoRBAC(user, PERMISSOES.CRIAR_EXAME) &&
                            <SttButton
                                type="button"
                                variant="contained"
                                color="primary"
                                onClick={handleAbrirModalEnvioImagens}
                                nomarginleft="true"
                            >
                                {strings.enviarExame}
                            </SttButton>
                        }
                    </>
            }

            {
                temPermissaoRBAC(user, PERMISSOES.CRIAR_EXAME) &&
                <EnvioImagens
                    modalOpen={mostrarModalEnvioImagens}
                    resetFormulario={() => {
                        setMostrarModalEnvioImagens(false);
                    }}
                    solicitacao={dadosEnvioImagens}
                    callbackFinalizarEnvio={callbackEnvioImagens}
                    key={idComponenteEnvioImagens}
                />
            }
        </SttContainer>
    )

}

export default Detalhes;