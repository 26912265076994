import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Interno from './interno';
import { useSelector } from 'react-redux';
import { SttAlerta } from '@stt-componentes/core';
import Erro from './erro';

const Home = () => {
    const alerta = useSelector(state => state.alerta);

    return (
        <BrowserRouter basename={`/${global.gConfig.basename}`}>
            <Routes>
                <Route path="/erro" exact element={<Erro />} />
                <Route path="*" element={<Interno />} />
            </Routes>
            <SttAlerta {...alerta} />
        </BrowserRouter>
    );
};

export default Home;
