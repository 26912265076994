import * as yup from 'yup';
import { 
    DATA_DE,
    DATA_ATE
} from './fieldNames';

export default (strings) => {
   return yup.object().shape({
        [DATA_DE]: yup.date().typeError(strings.dataInvalida).max(new Date(), strings.erroDataFutura).nullable(),
        [DATA_ATE]: yup.date().typeError(strings.dataInvalida).min(yup.ref(DATA_DE), strings.erroDataMenorDataInicio).max(new Date(), strings.erroDataFutura).nullable()
    });
}