import React, { useContext, useEffect } from 'react';
import { FIELDS } from './fieldNames';
import { SttAutocomplete, SttTranslateHook } from '@stt-componentes/core';

const Equipamento = (props) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { form, field, equipamentos, meta } = props;
    const { name, value, onBlur } = field;
    const { setFieldValue } = form;

    useEffect(() => {
        if (equipamentos.length === 1) {
            setFieldValue(FIELDS.EQUIPAMENTO, equipamentos[0]);
        }
    }, [equipamentos]);

    return (
        <SttAutocomplete
            inputprops={{
                name: name,
                label: strings.equipamento,
                required: true,
                error: meta.touched && meta.error ? meta.error : undefined
            }}
            {...field}
            getOptionLabel={option => option?.id_humano || ''}
            options={equipamentos}
            value={value}
            onBlur={onBlur}
            onChange={(e, item) => setFieldValue(FIELDS.EQUIPAMENTO, item || null)}
        />
    )
}

export default Equipamento;