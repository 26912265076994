import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom'
import { makeStyles } from "@material-ui/core/styles";
import StickyBox from "react-sticky-box";
import PropTypes from 'prop-types';
import axios from 'axios';
import { getHeaders } from '../../request';
import { SttGrid, SttButton, SttHidden, SttLoading, SttTranslateHook, SttNotification } from '@stt-componentes/core';
import Laudo from './laudo';
import Imagens from '../anexos/imagens';
import Invalidar from '../exame/invalidar';
import { useMoment } from '../../hooks';
import { open, configure } from '../../reducers/alerta';

const useStyles = makeStyles(theme => ({
    wrapperBotao: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    modalBody: {
        overflow: 'hidden'
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    }
}));

const ConteinerLaudo = ({ idExame, idRede, callbackProximoExame, callbackExameCarregado, notificar, setLaudar = () => { } }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const dispatch = useDispatch();

    const EEG_API_BASE_URL = global.gConfig.url_base_eeg;

    const location = useLocation();
    const moment = useMoment();
    const classes = useStyles();
    const [exame, setExame] = useState(null);
    const [contextoFabricalaudo] = useState(location.pathname === '/laudo');
    const [imagensAtualizadas, setImagensAtualizadas] = useState(false);

    // Invalidação do exame
    const [invalidar, setInvalidar] = useState(false);

    const handleCloseAlerta = () => {
        if (contextoFabricalaudo) {
            callbackProximoExame();
        } else {
            setLaudar(false);
        }
    }

    useEffect(() => {
        if (idExame) {
            axios.get(`${EEG_API_BASE_URL}/laudo/${idExame}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        let { data } = response.data;
                        let dataMoment = moment(data.exame.data_nascimento_paciente);
                        if (dataMoment.isValid()) {
                            var now = moment();
                            var dataMomentFormatada = moment(dataMoment, 'DD-MM-YYYY');
                            var idade = moment.duration(now.diff(dataMomentFormatada));
                            var anos = idade.years();
                            var meses = idade.months();
                            var dias = idade.days();
                            data.exame.data_nascimento_paciente_formatada += ` (${anos} ano(s), ${meses} mes(es) e ${dias} dia(s))`
                        }
                        setExame(data);
                        if (contextoFabricalaudo) {
                            callbackExameCarregado();
                        }
                    }
                })
                .catch(err => {
                    console.log(err);
                    const { response } = err;
                    let msg = strings.erroDesconhecido;
                    let arrMensagem = [];
                    if (response) {
                        const { data } = response;
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        msg = arrMensagem.join('\n');
                    }

                    const alertConfig = {
                        title: strings.erro,
                        message: msg,
                        type: 'error',
                        open: true,
                        options: [
                            {
                                title: strings.ok,
                                onClick: () => {
                                    dispatch(open(false));
                                    handleCloseAlerta();
                                }
                            }
                        ],
                        onClose: () => {
                            dispatch(open(false));
                            handleCloseAlerta();
                        }
                    };
                    dispatch(configure(alertConfig));
                });
        }

        return () => {
            setLaudar(false);
            cancelarEmissao();
        }
    }, []);

    useEffect(() => {
        if (!imagensAtualizadas) {
            setImagensAtualizadas(true);
        }
    }, [imagensAtualizadas]);

    const voltar = () => {
        // Controle de componentes. Volta para tela anterior
        setLaudar(false);

        // Cancela emissão do laudo
        cancelarEmissao();
    }

    const cancelarEmissao = () => {
        axios.post(`${EEG_API_BASE_URL}/laudo/cancelar`, { idExame }, { headers: getHeaders() })
            .catch(err => console.log(err));
    }

    const ignorarExame = () => {
        setExame(null);
        notificar(strings.ignorandoExame);
        // Insere exame ignorado
        axios
            .post(`${EEG_API_BASE_URL}/laudo/ignorar-exame`, { idExame }, { headers: getHeaders(), timeout: 15000 })
            .then((resposta) => {
                // Carrega o próximo exame
                callbackProximoExame();
            })
            .catch(err => {
                console.log(err);
                const alertConfig = {
                    title: strings.erro,
                    message: strings.erroIgnorarExame,
                    type: 'error',
                    open: true,
                    options: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                dispatch(open(false));
                                handleCloseAlerta();
                            }

                        }
                    ],
                    onClose: () => {
                        dispatch(open(false));
                        handleCloseAlerta();
                    }
                };
                dispatch(configure(alertConfig));
            });
    }

    return (
        <>
            <div className={classes.carregando}>
                <SttLoading
                    open={!exame && !contextoFabricalaudo}
                    text={strings.carregandoExame}
                />
            </div>
            {
                exame &&
                <>
                    <SttGrid container spacing={3}>
                        <SttGrid item xs={12} className={classes.wrapperBotao}>
                            {
                                <SttButton
                                    variant="contained"
                                    danger={+true}
                                    onClick={() => setInvalidar(true)}
                                >
                                    {strings.invalidar}
                                </SttButton>
                            }

                            {
                                !contextoFabricalaudo &&
                                <SttButton
                                    type="button"
                                    variant="outlined"
                                    color="primary"
                                    onClick={voltar}
                                >
                                    {strings.voltar}
                                </SttButton>
                            }

                            {
                                contextoFabricalaudo &&
                                <SttButton
                                    type="button"
                                    variant="outlined"
                                    color="primary"
                                    onClick={ignorarExame}
                                >
                                    {strings.proximoExame}
                                </SttButton>
                            }

                        </SttGrid>
                    </SttGrid>
                    <SttGrid container spacing={3}>
                        <SttHidden mdUp>
                            <SttGrid item xs={12}>
                                {
                                    imagensAtualizadas &&
                                    <Imagens idExame={idExame} />
                                }
                            </SttGrid>
                            <SttGrid item xs={12}>
                                <Laudo dados={exame} callbackProximoExame={callbackProximoExame} setImagensAtualizadas={setImagensAtualizadas} setLaudar={setLaudar} />
                            </SttGrid>
                        </SttHidden>
                        <SttHidden only={['xs', 'sm']}>
                            <SttGrid item xs={6}>
                                <StickyBox>
                                    {
                                        imagensAtualizadas &&
                                        <Imagens idExame={idExame} />
                                    }
                                </StickyBox>
                            </SttGrid>
                            <SttGrid item xs={6}>
                                <Laudo dados={exame} callbackProximoExame={callbackProximoExame} setImagensAtualizadas={setImagensAtualizadas} setLaudar={setLaudar} />
                            </SttGrid>
                        </SttHidden>
                    </SttGrid>
                </>
            }
            {
                invalidar &&
                <Invalidar
                    invalidar={invalidar}
                    setInvalidar={setInvalidar}
                    idExame={idExame}
                    idRede={idRede}
                    callback={callbackProximoExame}
                />
            }
        </>
    );
};

ConteinerLaudo.propTypes = {
    idExame: PropTypes.number.isRequired,
    callbackProximoExame: PropTypes.func,
    callbackExameCarregado: PropTypes.func
};

export default ConteinerLaudo;