import React, { useEffect, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import { getHeaders } from '../../request';
import { atualizarBusca } from '../../reducers/exame';
import { open, configure } from '../../reducers/alerta';
import axios from 'axios';
import { MODALIDADE } from '../../common/Constants';
import { SttLoading, SttGrid, SttButton, SttModal, SttAutocomplete, SttTranslateHook } from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    modalBody: {
        overflow: 'hidden'
    }
}));
const Priorizar = (props) => {

    const { priorizar, setPriorizar, idExame, idInstituicao, callback } = props;
    const dispatch = useDispatch();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    const [motivosPriorizacao, setMotivosPriorizacao] = useState([]);
    const [motivo, setMotivo] = useState(null);
    const [priorizando, setPriorizando] = useState(false);
    const EXAME_API_BASE_URL = global.gConfig.url_base_exames;

    useEffect(() => {

        // Carrega a lista de motivos de priorização de exames
        if (!motivosPriorizacao.length) {
            axios
                .get(`${EXAME_API_BASE_URL}/motivo-priorizacao`, { params: { siglaModalidade: MODALIDADE.SIGLA }, headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        setMotivosPriorizacao(response.data);
                    }
                })
                .catch(err => console.log(err));
        }
    }, []);

    const fecharPriorizar = () => {
        setMotivo(null);
        setPriorizar(false);
        if (callback) {
            callback();
        }
    }

    const selecionarMotivo = (event, novoMotivo) => {
        setMotivo(novoMotivo);
    }

    const priorizarExame = () => {
        setPriorizando(true);

        const dados = {
            exame: idExame,
            motivo: motivo.id,
            instituicao: idInstituicao,
            modalidade: MODALIDADE.DESCRICAO_PT
        }
        axios
            .post(`${EXAME_API_BASE_URL}/priorizar`, dados, { headers: getHeaders() })
            .then((resposta) => {
                setPriorizando(false);
                const alertConfig = {
                    title: strings.sucesso,
                    message: strings.examePriorizado,
                    type: 'success',
                    open: true,
                    options: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                dispatch(open(false));
                                fecharPriorizar();
                                dispatch(atualizarBusca(true));
                            }

                        }
                    ],
                    onClose: () => {
                        dispatch(open(false));
                        fecharPriorizar();
                        dispatch(atualizarBusca(true));
                    }
                };
                dispatch(configure(alertConfig));
            })
            .catch(err => {
                setPriorizando(false);
                console.log(err);
                const alertConfig = {
                    title: strings.erro,
                    message: strings.erroPriorizarExame,
                    type: 'error',
                    open: true,
                    options: [
                        {
                            title: strings.ok,
                            onClick: () => dispatch(open(false))

                        }
                    ],
                    onClose: () => dispatch(open(false))
                };
                dispatch(configure(alertConfig));
            });
    }

    return (
        <>
            <SttModal
                title={strings.priorizar}
                open={priorizar}
                outModalClose={fecharPriorizar}
                iconClose={fecharPriorizar}
                maxWidth="sm"
                fullWidth={true}
                children={
                    <div className={classes.modalBody}>
                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12}>
                                <SttAutocomplete
                                    inputprops={{
                                        label: strings.motivo,
                                        required: true
                                    }}
                                    getOptionLabel={option => option.descricao}
                                    options={motivosPriorizacao}
                                    value={motivo}
                                    onChange={selecionarMotivo}
                                />
                            </SttGrid>
                        </SttGrid>
                    </div>
                }
                footer={
                    <div>
                        <SttButton
                            variant="contained"
                            color="primary"
                            disabled={!motivo}
                            onClick={() => {
                                const alertConfig = {
                                    title: strings.atencao,
                                    message: strings.confirmarPriorizarExame,
                                    type: 'confirmation',
                                    open: true,
                                    options: [
                                        {
                                            title: strings.sim,
                                            onClick: () => {
                                                priorizarExame();
                                                dispatch(open(false));
                                            }
                                        },
                                        {
                                            title: strings.nao,
                                            onClick: () => dispatch(open(false))
                                        }
                                    ],
                                    onClose: () => dispatch(open(false))
                                };
                                dispatch(configure(alertConfig));
                            }}
                        >
                            {strings.confirmar}
                        </SttButton>
                        <SttButton variant="outlined" color="primary" onClick={fecharPriorizar}>
                            {strings.cancelar}
                        </SttButton>
                    </div>
                }
            />

            <div className={classes.carregando}>
                <SttLoading
                    open={priorizando}
                    text={strings.priorizandoExame}
                />
            </div>
        </>
    );
}

export default Priorizar;
