import React, { useState, useEffect, useContext } from 'react';
import {
    SttGrid,
    SttFileSelect,
    SttButton,
    SttTranslateHook,
    SttNotification
} from '@stt-componentes/core';
import { Field, FieldArray, useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const useStyles = makeStyles(theme => ({
    divAnexo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        minHeight: '250px'
    },
    imgAtual: {
        maxHeight: '320px',
        maxWidth: '320px',
        margin: 'auto'
    },
    botaoMais: {
        minWidth: 0,
        marginLeft: 0
    },

    botaoMenos: {
        minWidth: 0
    },

    divExternaAnexos: {
        maxHeight: '500px',
        overflow: 'auto'
    },
    container: {
        marginBottom: 0,
        marginTop: theme.spacing(1)
    }
}));

const Anexos = ({ exibirAviso = true }) => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { setFieldValue, values } = useFormikContext();

    const [anexos, setAnexos] = useState([]);

    const uploadFile = (event, indice, removerAnexo) => {
        //Caso o anexo tenha sido excluída, remove a mesma do array de anexos no formulário e retira do array de anexos
        if (!event) {
            if (anexos.length > 1) {
                removerAnexo(indice);
            } else {
                setFieldValue(`anexo.${(indice)}`, {});
            }

            setAnexos(anexos => anexos.filter((anexo, i) => i !== indice));
        } else {
            if (event.target.files[0]) {
                //Trata nome e blob do anexo para exibição no carousel
                lerAnexoBase64(event.target.files[0]);
                //Seta o valor do anexo no campo correspondente do formulário
                setFieldValue(`anexo.${(indice)}`, event.target.files[0]);
            }
        }
    };

    useEffect(() => {
        if (anexos.length === 0 && values['anexo']) {
            //Trata nome e blob do anexo para exibição no carousel
            values['anexo'].forEach(file => {
                if (file && file.name) {
                    lerAnexoBase64(file);
                }
            });
        }
    }, []);

    const lerAnexoBase64 = (file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setAnexos(anexs => [
                ...anexs,
                {
                    nome: file.name,
                    anexo: reader.result,
                    ehImagem: file.type !== 'application/pdf'
                }
            ]);
        };
    }

    const validarAnexo = (value) => {
        let error;
        if (value && !['image/png', 'image/bmp', 'image/jpeg', 'image/jpg', 'application/pdf'].includes(value.type)) {
            error = strings.anexoInvalido;
        }
        return error;
    }

    return (
        <>
            {
                exibirAviso &&
                <SttNotification severity="info" style={{ marginTop: '10px' }}>{strings.inclusaoImagemOpcional}</SttNotification>
            }
            <SttGrid container spacing={3} justifyContent="center" className={classes.container}>
                <SttGrid item xs={12} md={6}>
                    <FieldArray
                        name={'anexo'}
                        render={({ remove, push }) => (
                            <>
                                <div className={classes.divExternaAnexos}>
                                    {values['anexo'] && values['anexo'].length > 0 &&
                                        values['anexo'].map((anexo, indice) => (
                                            <div key={indice}>
                                                <Field name={`anexo.${(indice)}`} validate={validarAnexo}>
                                                    {({
                                                        field: { name, value },
                                                        meta
                                                    }) => (
                                                        <>
                                                            <SttFileSelect
                                                                labelInput={strings.anexo}
                                                                onFileChange={(event) => uploadFile(event, indice, remove)}
                                                                file={value}
                                                                inputprops={{
                                                                    name: name,
                                                                    label: strings.anexo,
                                                                    value: value && value.name,
                                                                    error: meta.touched && meta.error ? meta.error : undefined
                                                                }}
                                                                accept={["image/jpg", "image/jpeg", "image/png", "image/bmp", "application/pdf"]}
                                                            />
                                                        </>
                                                    )}
                                                </Field>
                                            </div>
                                        ))}
                                </div>

                                <SttButton
                                    className={classes.botaoMais}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        push({});
                                    }}
                                >
                                    +
                                </SttButton>
                                <SttButton
                                    className={classes.botaoMenos}
                                    variant="contained"
                                    disabled={values['anexo'] && values['anexo'].length == 1}
                                    onClick={() => {
                                        if (values['anexo'].length > 1) {
                                            let indice = values['anexo'].length - 1;
                                            remove(indice);
                                            setAnexos(anexs => anexs.filter((anexo, i) => i !== indice));
                                        }
                                    }}
                                    color="primary">
                                    -
                                </SttButton>
                            </>
                        )}
                    />
                </SttGrid>

                <SttGrid item xs={12} md={6}>
                    <Carousel statusFormatter={(current, total) => `Imagem ${current} de ${total}`}>
                        {
                            anexos.filter(a => a.ehImagem).map((anexo, indice) => {
                                return (
                                    <div className={classes.divAnexo} key={indice}>
                                        <img src={anexo.anexo} className={classes.imgAtual} />
                                        <p className="legend">{anexo.nome}</p>
                                    </div>
                                );
                            })
                        }
                    </Carousel>
                </SttGrid>
            </SttGrid>
        </>
    );
}

export default Anexos;