import React, { useContext, useRef, useState } from 'react';
import HttpStatus from 'http-status-codes';
import { getHeaders } from '../../request';
import axios from 'axios';
import {
    SttModal,
    SttButton,
    SttLoading,
    SttTranslateHook,
    SttGrid
} from '@stt-componentes/core';
import { Formik } from 'formik';
import Anexos from '../envio-imagens/anexos';
import { makeStyles } from '@material-ui/core';
import * as yup from 'yup';
import { configure, open } from '../../reducers/alerta';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
    container: {
        marginBottom: 0
    }
}));

const validationSchema = (strings) => {
    return yup.object().shape({
        'anexo': yup
            .array()
            .min(1)
            .of(
                yup.object()
                    .nullable()
            )
            .required(strings.campoObrigatorio)
    });
}

const initialValues = {
    anexo: [{}]
}

const ModalNovosAnexos = ({ idExame, setImagensAtualizadas, exibirModalNovosAnexos, setExibirModalNovosAnexos }) => {
    const classes = useStyles();

    const { strings } = useContext(SttTranslateHook.I18nContext);
    const schema = validationSchema(strings);

    const formRef = useRef();
    const dispatch = useDispatch();

    const fecharModal = () => {
        setExibirModalNovosAnexos(false);
    }

    return (
        <>
            <SttModal
                title={strings.inclusaoNovosAnexos}
                open={exibirModalNovosAnexos}
                outModalClose={fecharModal}
                iconClose={fecharModal}
                maxWidth="lg"
                fullWidth={true}
                children={
                    <Formik
                        innerRef={formRef}
                        initialValues={initialValues}
                        validationSchema={schema}
                        onSubmit={(dados, { setSubmitting, resetForm }) => {
                            console.log(dados)
                            setSubmitting(true);
                            // submitForm(data, setSubmitting, resetForm);
                            const formData = new FormData();
                            formData.append('idExame', idExame);

                            dados.anexo.forEach((a, index) => {
                                if (a && (a instanceof File)) {
                                    formData.append(`nome_anexos.${index}`, a.name);
                                    formData.append(`anexo.${index}`, a);
                                }
                            });

                            axios.post(`${global.gConfig.url_base_eeg}/exame-anexo`, formData, { headers: { ...getHeaders(), 'Content-Type': 'multipart/form-data' } })
                                .then((response) => {
                                    const alertConfig = {};
                                    if (response.status === HttpStatus.CREATED) {
                                        alertConfig.title = strings.sucesso;
                                        alertConfig.message = strings.anexosInseridosSucesso;
                                        alertConfig.type = 'success';
                                        alertConfig.open = true;
                                        alertConfig.onClose = () => {
                                            setImagensAtualizadas(false);
                                            setExibirModalNovosAnexos(false);
                                            dispatch(open(false));
                                        }
                                        alertConfig.options = [
                                            {
                                                title: strings.ok,
                                                onClick: () => {
                                                    setImagensAtualizadas(false);
                                                    setExibirModalNovosAnexos(false);
                                                    dispatch(open(false));
                                                }

                                            }
                                        ]
                                        dispatch(configure(alertConfig));
                                    } else {
                                        const alertConfig = {
                                            title: strings.erro,
                                            message: strings.mensagemErro,
                                            type: 'error',
                                            open: true,
                                            options: [
                                                {
                                                    title: strings.ok,
                                                    onClick: () => dispatch(open(false))

                                                }
                                            ],
                                            onClose: () => dispatch(open(false))
                                        };
                                        dispatch(configure(alertConfig));
                                    }
                                })
                                .catch(err => {
                                    const { response } = err;
                                    let msg = strings.mensagemErroGeral;
                                    let titulo = strings.erro;
                                    let msgAlerta = '';

                                    if (response) {
                                        if (response.status === HttpStatus.BAD_REQUEST) {
                                            const dadosResp = response.data;
                                            let arrMensagem = [];
                                            dadosResp.errors.forEach(error => {
                                                arrMensagem.push(`- ${error.message}`);
                                            });
                                            msg = arrMensagem.join('\n');
                                            msgAlerta = msg;
                                        } else {
                                            msgAlerta = msg;
                                        }
                                    } else {
                                        msgAlerta = msg;
                                    }

                                    const alertConfig = {
                                        title: titulo,
                                        message: msgAlerta,
                                        type: 'error',
                                        open: true,
                                        options: [
                                            {
                                                title: strings.ok,
                                                onClick: () => dispatch(open(false))

                                            }
                                        ],
                                        onClose: () => dispatch(open(false))
                                    };
                                    dispatch(configure(alertConfig));
                                })
                                .finally(() => {
                                    setSubmitting(false);
                                });
                        }}
                    >
                        {
                            ({ handleSubmit, isSubmitting }) => {
                                return (
                                    <form noValidate onSubmit={handleSubmit}>
                                        <SttGrid container spacing={3} className={classes.container}>
                                            <SttGrid item xs={12}>
                                                <Anexos exibirAviso={false} />
                                            </SttGrid>
                                        </SttGrid>
                                        <SttLoading
                                            open={isSubmitting}
                                            text={strings.salvandoMensagemEspera}
                                        />
                                    </form>
                                )
                            }
                        }
                    </Formik>
                }
                footer={
                    <div>
                        <SttButton variant="contained" color="primary" onClick={() => formRef.current.submitForm()}>
                            {strings.confirmar}
                        </SttButton>
                        <SttButton variant="outlined" color="primary" onClick={fecharModal}>
                            {strings.cancelar}
                        </SttButton>
                    </div>
                }
            />
        </>
    );
}

export default ModalNovosAnexos;