import React, { useContext, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import Form from './form';
import {
    SttExpansionPanel,
    SttTextItem,
    SttHeading,
    SttGrid,
    SttTranslateHook,
    SttNotification,
    SttDivider
} from '@stt-componentes/core';
import ModalNovosAnexos from './modalAnexo';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    titulo: {
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'column'
    },
    conteinerLesao: {
        marginBottom: theme.spacing(1)
    },
    textItemWrapper: {
        padding: 0
    },
    notification: {
        margin: theme.spacing(1),
        cursor: 'pointer'
    },
    divider: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5)
    }
}));

const Laudo = (props) => {
    const { dados, setLaudar, setImagensAtualizadas, callbackProximoExame } = props;
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    const exame = dados;

    const [exibirModalNovosAnexos, setExibirModalNovosAnexos] = useState(false);

    return (
        <SttGrid container spacing={3}>
            <SttGrid item xs={12}>
                <SttHeading variant="h3" color="primary" align="center" className={classes.titulo}>
                    <span>{`${dados.exame.descricao_exame} - ${dados.exame.data_exame}`}</span>
                    <span>{`${dados.exame.nome_instituicao} - ${dados.exame.nome_cidade}/${dados.exame.sigla_uf}`}</span>
                </SttHeading>

                <SttExpansionPanel
                    title={strings.paciente}
                    compact
                    children={
                        <div>
                            <SttTextItem key="1" title={strings.nome} content={dados.exame.nome_paciente} wrapperClass={classes.textItemWrapper} />
                            <SttTextItem key="2" title={strings.dataNascimento} content={dados.exame.data_nascimento_paciente_formatada} wrapperClass={classes.textItemWrapper} />
                            <SttTextItem key="3" title={strings.sexo} content={dados.exame.sexo_paciente} wrapperClass={classes.textItemWrapper} />
                            <SttTextItem key="4" title={strings.peso} content={`${dados.indicacao.peso_paciente} kg`} wrapperClass={classes.textItemWrapper} />
                            <SttTextItem key="5" title={strings.altura} content={`${dados.indicacao.altura_paciente} cm`} wrapperClass={classes.textItemWrapper} />
                        </div>
                    }
                />

                {
                    dados.indicacao &&
                    <>
                        <SttDivider className={classes.divider} />
                        <SttExpansionPanel
                            title={strings.indicacaoClinica}
                            compact
                            children={
                                <div>
                                    {dados.indicacao.antededentes && <SttTextItem title={strings.antecedentes} content={dados.indicacao.antecedentes} />}
                                    {dados.indicacao.indicacao_exame && <SttTextItem title={strings.indicacaoExame} content={dados.indicacao.indicacao_exame} />}
                                    {dados.indicacao.historia_clinica && <SttTextItem title={strings.historiaClinica} content={dados.indicacao.historia_clinica} />}
                                    {dados.indicacao.medicamentos_em_uso && <SttTextItem title={strings.medicamentosEmUso} content={dados.indicacao.medicamentos_em_uso} />}
                                    {dados.indicacao.resultado_exames_anteriores && <SttTextItem title={strings.resultadosExamesAnteriores} content={dados.indicacao.resultado_exames_anteriores} />}
                                    {dados.exame.observacao_tecnica && <SttTextItem title={strings.observacaoTecnica} content={dados.exame.observacao_tecnica} />}
                                </div>
                            }
                        />
                    </>
                }

                <SttDivider className={classes.divider} />

                <SttExpansionPanel
                    title={strings.inclusaoNovosAnexos}
                    compact
                    opened={false}
                    children={
                        <div>
                            <SttNotification severity="info" className={classes.notification} onClick={() => setExibirModalNovosAnexos(true)}>
                                {strings.adicionarNovosAnexos}
                            </SttNotification>
                        </div>
                    }
                />

                <ModalNovosAnexos
                    idExame={dados.exame.id_exame}
                    setImagensAtualizadas={setImagensAtualizadas}
                    exibirModalNovosAnexos={exibirModalNovosAnexos}
                    setExibirModalNovosAnexos={setExibirModalNovosAnexos}
                />

                <SttDivider className={classes.divider} />

                <Form
                    idExame={exame.exame.id_exame}
                    idLaudoEmissao={exame.idLaudoEmissao}
                    idPaciente={exame.exame.id_paciente}
                    idSolicitante={exame.exame.id_medico_solicitante}
                    nomeSolicitante={exame.exame.nome_medico_solicitante}
                    callbackProximoExame={callbackProximoExame}
                    setLaudar={setLaudar}
                />
            </SttGrid>
        </SttGrid>
    )

}

Laudo.propTypes = {
    dados: PropTypes.object.isRequired,
    callbackProximoExame: PropTypes.func
};

export default Laudo;