export const PERFIL = {
    MEDICO_SOLICITANTE: 'medicoSolicitante',
    MEDICO_LAUDADOR: 'medicoLaudador',
    ADMINISTRADOR: 'administrador',
    TECNICO: 'tecnico',
    REGULADOR_EXAME: 'reguladorExame',
    VISUALIZADOR_REDE: 'visualizadorRede',
    ADMINISTRADOR_ESTADUAL: 'adminEstadual',
};

export const PERMISSOES = {
    ADMINISTRATIVO: 'EEG_ADMINISTRATIVO',
    CRIAR_EXAME: 'EEG_CRIAR_EXAME',
    EEG: 'EEG_EEG',
    INVALIDAR_EXAME: 'EEG_INVALIDAR_EXAME',
    INVALIDAR_EXAME_LAUDADO: 'EEG_INVALIDAR_EXAME_LAUD',
    LAUDAR_EXAME: 'EEG_LAUDAR_EXAME',
    LAUDAR_EXAME_FABRICA: 'EEG_LAUDAR_EXAME_FABRICA',
    PRIORIZAR_EXAME: 'EEG_PRIORIZAR_EXAME',
    SOLICITAR_EXAME: 'EEG_SOLICITAR_EXAME',
    TROCAR_REDE: 'EEG_TROCAR_REDE',
    VISUALIZAR_EXAME: 'EEG_VISUALIZACAO',
    VISUALIZAR_IMAGEM: 'EEG_VISUALIZAR_IMAGEM',
    VISUALIZAR_LAUDO: 'EEG_VISUALIZAR_LAUDO',
    VISUALIZAR_SOLICITACAO: 'EEG_VISUALIZAR_SOLIC_EX'
};

export const MODALIDADE = {
    SIGLA: 'EEG',
    DESCRICAO: 'Electroencephalogram',
    CODIGO: '1.2.826.0.1.3680043.8.213.50',
    DESCRICAO_PT: 'Eletroencefalograma'
};

export const ORIGEM_MANUAL = 'M';

export const CONSELHO_TRABALHO = {
    CRM: 'CRM'
}

export const TIPO_ANEXO = {
    PDF: 'pdf',
    JPG: 'jpg',
    JPEG: 'jpeg',
    PNG: 'png',
    BMP: 'bmp'
}