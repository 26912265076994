import React, { useState, useEffect, useContext } from 'react';
import DadosGerais from '../informacoes-gerais/dadosGerais';
import { FIELDS } from './fieldNames';
import { useSelector } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import Anexos from '../anexos';
import {
    SttModal,
    SttButton,
    SttStepper,
    SttMobileStepper,
    SttHidden,
    SttTranslateHook
} from '@stt-componentes/core';
import { useFormikContext } from 'formik';

const useStyles = makeStyles(theme => ({
    mobileStepper: {
        marginBottom: theme.spacing(1)
    }
}));

const InformacoesGerais = (props) => {
    const {
        confirmarFecharModal,
        open,
        callbackFinalizado,
        equipamentos,
        steps,
        stepAtual,
        setStepAtual
    } = props;
    const classes = useStyles();

    const { strings } = useContext(SttTranslateHook.I18nContext);
    const user = useSelector(state => state.index.user);
    const { setFieldValue, values, touched, setTouched, validateForm } = useFormikContext();

    const [selecionarAnexos, setSelecionarAnexos] = useState(false);

    useEffect(() => {
        if (equipamentos?.length === 1) {
            setFieldValue(FIELDS.EQUIPAMENTO, equipamentos[0]);
        }
    }, [equipamentos]);

    const proximoStep = () => {
        setStepAtual(stepAtual + 1);
        setSelecionarAnexos(true);
    }

    const stepAnterior = () => {
        setStepAtual(stepAtual - 1);
        setSelecionarAnexos(false);
    }

    const finalizarConjuntoSteps = (val) => {
        callbackFinalizado(val);
    }

    return (
        <SttModal
            title={strings.envioImagens}
            open={open}
            outModalClose={confirmarFecharModal}
            iconClose={confirmarFecharModal}
            maxWidth="lg"
            fullWidth={true}
            children={
                <>
                    <SttHidden only={['xs', 'sm']}>
                        <SttStepper steps={steps.map((step) => step.titulo)} activeStep={stepAtual} />
                        {
                            !selecionarAnexos ?
                                <DadosGerais strings={strings} user={user} equipamentos={equipamentos} /> :
                                <Anexos
                                    strings={strings}
                                    steps={steps}
                                    open={selecionarAnexos}
                                    validateForm={validateForm}
                                    touched={touched}
                                    setTouched={setTouched}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                />
                        }

                    </SttHidden>
                    <SttHidden mdUp>
                        {
                            !selecionarAnexos ?
                                <DadosGerais strings={strings} user={user} equipamentos={equipamentos} /> :
                                <Anexos
                                    strings={strings}
                                    steps={steps}
                                    open={selecionarAnexos}
                                    validateForm={validateForm}
                                    touched={touched}
                                    setTouched={setTouched}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                />
                        }
                        <SttMobileStepper
                            className={classes.mobileStepper}
                            steps={steps.length}
                            activeStep={stepAtual}
                        />
                    </SttHidden>
                </>
            }
            footer={
                <>
                    {
                        stepAtual > 0 &&
                        <SttButton
                            variant="outlined"
                            color="primary"
                            onClick={stepAnterior}>
                            {strings.anterior}
                        </SttButton>
                    }
                    {
                        stepAtual < steps.length - 1 &&
                        <SttButton
                            variant="contained"
                            color="primary"
                            onClick={() => validateForm().then((erros) => {
                                if (Object.keys(erros).length) {
                                    setTouched({ ...touched, ...erros });
                                    return;
                                }
                                proximoStep();
                            })}>
                            {strings.proximo}
                        </SttButton>
                    }
                    {
                        stepAtual === steps.length - 1 &&
                        <SttButton
                            variant="contained"
                            color="primary"
                            onClick={finalizarConjuntoSteps}>
                            {strings.enviarExame}
                        </SttButton>
                    }
                    <SttButton
                        variant="outlined"
                        color="secondary"
                        onClick={confirmarFecharModal}
                    >
                        {strings.cancelar}
                    </SttButton>
                </>
            }
        />
    );
}

export default InformacoesGerais;