import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { getHeaders } from '../../request';
import { SttTranslateHook } from '@stt-componentes/core';
import ListaAnexos from './anexos';
import SttGaleriaImagens from '@stt-componentes/galeria-telediagnostico';

const useStyles = makeStyles((theme) => ({
    divListaImagens: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
}));

const Imagens = ({ idExame }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    const [anexos, setAnexos] = useState([]);

    useEffect(() => {
        axios.get(`${global.gConfig.url_base_exames}/exame/${idExame}/anexo`, { headers: getHeaders() })
            .then((response) => {
                setAnexos(response.data);
            })
            .catch(err => {
                console.log('err', err);
            });
    }, []);

    return (
        <>
            <SttGaleriaImagens
                idExame={idExame}
                strings={strings}
                config={global.gConfig}
                tokenTelessaude={getHeaders()}
            />
            {
                anexos.length > 0 &&
                <div className={classes.divListaImagens}>
                    <ListaAnexos anexos={anexos} />
                </div>
            }
        </>
    )
}

export default Imagens;