import * as yup from 'yup'

import { PACIENTE } from '@stt-componentes/paciente/dist/lib/form/fieldNames';

import solicitanteSchema from '../../componentes/solicitacao/solicitante/validationSchema';
import informacoesClinicasSchema from '../../componentes/solicitacao/informacoes-clinicas/validationSchema';
import pacienteSchema from '@stt-componentes/paciente/dist/lib/form/validationSchema';

export default (strings, campos) => {

    const paciente = yup.object().shape({ [PACIENTE]: pacienteSchema(strings, campos) });
    const solicitante = solicitanteSchema(strings);
    const informacoesClinicas = informacoesClinicasSchema(strings);

    const schema = yup.object().shape({});

    return schema
        .concat(informacoesClinicas)
        .concat(solicitante)
        .concat(paciente);
}