import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import axios from 'axios';
import HttpStatus from 'http-status-codes';
import { getHeaders } from '../../request';
import { validationDadosGerais } from './informacoes-gerais/validationSchema';
import { open, configure } from '../../reducers/alerta';
import {
    SttLoading,
    SttTranslateHook
} from '@stt-componentes/core';
import ModalDadosGerais from './informacoes-gerais';
import { MODALIDADE } from '../../common/Constants';
import Utils from '../../utils';

const initialValues = {
    equipamento: null,
    observacao: '',
    requisicao: '',
    resultadoExames: '',
    validarImagem: false,
    anexo: [{}]
};

const EnvioImagens = ({ modalOpen, resetFormulario, solicitacao = {}, handleCancelarEnvio, callbackFinalizarEnvio }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const user = useSelector(state => state.index.user);
    const dispatch = useDispatch();

    initialValues.resultadoExames = solicitacao.resultadoExames || '';

    const steps = [{ titulo: strings.dadosGerais }, { titulo: strings.anexos }];
    const [stepAtual, setStepAtual] = useState(0);

    // Controle do id do exame enviado para geração do protocolo
    const [exameEnviado, setExameEnviado] = useState(null);

    //Esquemas de validação
    const schemaInformacoesGerais = validationDadosGerais(strings, user);

    // Notificação
    const [progresso, setProgresso] = useState(false);
    const [mensagemProgresso, setMensagemProgresso] = useState(strings.mensagemEnviandoImagens);

    // Impressão do protocolo
    const [gerarProtocolo, setGerarProtocolo] = useState(false);

    // Impressão do TCLE
    const [gerarTermo, setGerarTermo] = useState(false);
    const [paciente, setPaciente] = useState(null);
    const [ufSolicitante, setUfSolicitante] = useState(null);

    // Controle de equipamento
    const [equipamentos, setEquipamentos] = useState([]);
    const [erroEquipamento, setErroEquipamento] = useState(false);


    useEffect(() => {
        let instituicoes = [];
        if (user.habilitacao?.solicitacaoServico?.length) {
            instituicoes = user.habilitacao.solicitacaoServico.map(inst => inst.id);
        }

        if (instituicoes.length) {
            const stringInsts = instituicoes.join();
            axios.get(`${global.gConfig.url_base_utilitarios}/equipamento?modalidade=${MODALIDADE.SIGLA}&instituicao=${stringInsts}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        setEquipamentos(response.data);
                    } else {
                        setEquipamentos([]);
                        setErroEquipamento(true);
                    }
                })
                .catch(err => console.log(err));
        }

    }, [user]);

    useEffect(() => {
        if (erroEquipamento) {
            const alertConfig = {
                title: strings.erro,
                message: strings.noaExisteEquipamento,
                type: 'error',
                open: true,
                options: [
                    {
                        title: strings.ok,
                        onClick: () => {
                            dispatch(open(false));
                            cancelarEnvio();
                        }
                    }
                ],
                onClose: () => {
                    dispatch(open(false));
                    cancelarEnvio();
                }
            };
            dispatch(configure(alertConfig));
        }

    }, [erroEquipamento]);

    const cancelarEnvio = () => {
        if (handleCancelarEnvio) {
            handleCancelarEnvio();
        }
        resetFormulario();
    }

    const confirmarFecharModal = () => {
        const alertConfig = {
            title: strings.tituloModalCancelamento,
            message: strings.mensagemAlertaCancelamento,
            type: 'alert',
            open: true,
            options: [
                {
                    title: strings.sim,
                    onClick: () => {
                        dispatch(open(false));
                        cancelarEnvio();
                    }
                },
                {
                    title: strings.nao,
                    onClick: () => {
                        dispatch(open(false));
                    }
                }
            ],
            onClose: () => {
                dispatch(open(false));
            }
        };
        dispatch(configure(alertConfig));
    }

    const finalizarEnvioImagens = () => {
        if (callbackFinalizarEnvio) {
            callbackFinalizarEnvio();
        }
        resetFormulario();
    }

    /**
     * Gera o protocolo do exame
     *
     */
    const imprimirProtocolo = (idExame) => {
        Utils.imprimirProtocoloFn({ id: idExame }, () => {});
    }

    /**
     * Gera o termo de autorização
     * 
     * @param {number} paciente
     */
    const imprimirTermo = (pac) => {
        Utils.imprimirTcleFn({ id: pac }, () => {
            setProgresso(false);
            finalizarEnvioImagens();
        });
    }

    useEffect(() => {
        if (exameEnviado) {
            setMensagemProgresso(strings.gerandoNumeroProtocolo);
            imprimirProtocolo(exameEnviado);
        }
    }, [gerarProtocolo]);

    useEffect(() => {
        if (paciente) {
            imprimirTermo(paciente);
        }
    }, [gerarTermo]);

    const submitForm = (dados, setSubmitting) => {
        setProgresso(true);

        const { anexo, ...dadosGerais } = dados;

        const formData = new FormData();
        formData.append('dadosGerais', JSON.stringify(dadosGerais));
        formData.append('idSolicitacao', solicitacao.id);
        formData.append('idIndicacaoEeg', solicitacao.idIndicacaoEeg);

        if (anexo.length > 0) {
            anexo.forEach((a, index) => {
                if (a && (a instanceof File)) {
                    formData.append(`nome_anexos.${index}`, a.name);
                    formData.append(`anexo.${index}`, a);
                }
            });
        } else {
            formData.append('semAnexo', 1);
        }

        axios.post(`${global.gConfig.url_base_eeg}/exame`, formData, { headers: { ...getHeaders(), 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                if (response.status === HttpStatus.CREATED) {
                    // Armazena o identificador do exame gerado
                    setExameEnviado(response.data.data.exame);
                    setPaciente(response.data.data.paciente);
                    setUfSolicitante(response.data.data.uf);
                    // Gera o protocolo
                    setGerarProtocolo(true);
                    // Gera o TCLE
                    setGerarTermo(true);
                } else {
                    setProgresso(false);

                    const alertConfig = {
                        title: strings.erro,
                        message: strings.mensagemErro,
                        type: 'error',
                        open: true,
                        options: [
                            {
                                title: strings.ok,
                                onClick: () => dispatch(open(false))

                            }
                        ],
                        onClose: () => dispatch(open(false))
                    };
                    dispatch(configure(alertConfig));
                }
            })
            .catch(err => {
                setProgresso(false);
                const { response } = err;
                let msg = strings.mensagemErroGeral;
                let titulo = strings.erro;
                let msgAlerta = '';

                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const dadosResp = response.data;
                        let arrMensagem = [];
                        dadosResp.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        msg = arrMensagem.join('\n');
                        msgAlerta = msg;
                    } else {
                        msgAlerta = msg;
                    }
                } else {
                    msgAlerta = msg;
                }

                const alertConfig = {
                    title: titulo,
                    message: msgAlerta,
                    type: 'error',
                    open: true,
                    options: [
                        {
                            title: strings.ok,
                            onClick: () => dispatch(open(false))

                        }
                    ],
                    onClose: () => dispatch(open(false))
                };
                dispatch(configure(alertConfig));
            })
            .finally(() => {
                setSubmitting(false);
            });
    }

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={schemaInformacoesGerais}
            onSubmit={(data, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                submitForm(data, setSubmitting, resetForm);
            }}
        >
            {
                ({ handleSubmit }) => {
                    return (
                        <form noValidate onSubmit={handleSubmit}>
                            {
                                <ModalDadosGerais
                                    open={modalOpen}
                                    steps={steps}
                                    stepAtual={stepAtual}
                                    setStepAtual={setStepAtual}
                                    callbackFinalizado={handleSubmit}
                                    confirmarFecharModal={confirmarFecharModal}
                                    solicitacao={solicitacao}
                                    equipamentos={equipamentos}
                                />
                            }

                            <SttLoading
                                open={progresso}
                                text={mensagemProgresso}
                            />
                        </form>
                    )
                }
            }
        </Formik>
    );
}

export default EnvioImagens;