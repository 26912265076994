import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import {
    SttHeader,
    SttMainNavigationBar,
    SttSplashScreen,
    MateriaisSuporte,
    SttTranslateHook,
    SttNotificationScreen,
    SttHelpdeskShortcut
} from '@stt-componentes/core';
import { temPermissaoRBAC } from '../secutity/acl';
import { PERMISSOES } from '../common/Constants';
import { getHeaders } from '../request';

const Menu = () => {
    const user = useSelector(state => state.index.user);
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const location = useLocation();
    const navigate = useNavigate();
    const [tabAtual, setTabAtual] = useState(0);
    const [submenus, setSubmenus] = useState([]);
    const [openSS, setOpenSS] = useState(false);
    const [openMS, setOpenMS] = useState(false);
    const [exibirNotificacao, setExibirNotificacao] = useState(false);

    useEffect(() => {
        let menuOptions = [];

        if (user) {
            if (!temPermissaoRBAC(user, PERMISSOES.EEG)) {
                navigate(`/nao-autorizado`);
                return;
            }

            setExibirNotificacao(true);

            menuOptions = [...menuOptions, {
                id: '/',
                text: strings.inicio,
                button: true,
                onClick: () => {
                    navigate("/");
                }
            }];

            if (temPermissaoRBAC(user, PERMISSOES.SOLICITAR_EXAME)) {
                menuOptions = [...menuOptions, {
                    id: '/solicitacao',
                    text: strings.solicitacao,
                    button: true,
                    onClick: () => {
                        navigate("/solicitacao");
                    }
                }];
            }

            if (temPermissaoRBAC(user, PERMISSOES.VISUALIZAR_SOLICITACAO) || temPermissaoRBAC(user, PERMISSOES.CRIAR_EXAME)) {
                menuOptions = [...menuOptions, {
                    id: '/imagens',
                    text: strings.envioImagens,
                    button: true,
                    onClick: () => {
                        navigate("/imagens");
                    }
                }];
            }

            if (temPermissaoRBAC(user, PERMISSOES.VISUALIZAR_EXAME)) {
                menuOptions = [...menuOptions, {
                    id: '/exames',
                    text: strings.exames,
                    button: true,
                    onClick: () => {
                        navigate("/exames");
                    }
                }];
            }

            if (temPermissaoRBAC(user, PERMISSOES.LAUDAR_EXAME_FABRICA)) {
                menuOptions = [...menuOptions, {
                    id: '/laudo',
                    text: strings.laudo,
                    button: true,
                    onClick: () => {
                        navigate("/laudo");
                    }
                }];
            }

            if (temPermissaoRBAC(user, PERMISSOES.ADMINISTRATIVO)) {
                menuOptions = [...menuOptions, {
                    id: '/administrativo',
                    text: strings.administrativo,
                    button: true,
                    onClick: () => {
                        navigate("/administrativo");
                    }
                }];
            }

            setSubmenus(menuOptions);
        }
    }, [user]);

    useEffect(() => {
        submenus.forEach((menu, i) => {
            if (location.pathname.includes(menu.id)) {
                setTabAtual(i);
            }
        });
    }, [submenus, location]);

    return (
        <div>
            <SttHeader
                titulo={global.gConfig.instancia_nome}
                config={global.gConfig}
                opcoesDuvida={{
                    onClickNotasAtualizacao: () => setOpenSS(!openSS),
                    onClickMateriaisSuporte: () => setOpenMS(!openMS),
                    strings
                }}
            />
            <SttMainNavigationBar
                titulo={global.gConfig.modulo_nome}
                config={global.gConfig}
                submenus={submenus}
                selectedTab={tabAtual}
                callbackNavigationBar={(a, b) => { }}
            />
            <SttSplashScreen
                modulo={global.gConfig.modulo_nome}
                versao={global.gConfig.modulo_versao}
                novidades={global.gConfig.modulo_novidades}
                textoAjuda={global.gConfig.texto_ajuda}
                perfisRBAC={user.perfisRBAC}
                open={openSS}
                setOpen={setOpenSS}
            />
            <MateriaisSuporte
                strings={strings}
                config={global.gConfig}
                headers={getHeaders()}
                open={openMS}
                setOpen={setOpenMS}
            />
            {
                exibirNotificacao &&
                <SttNotificationScreen
                    config={global.gConfig}
                    strings={strings}
                    headers={getHeaders()}
                    perfisRBAC={user.perfisRBAC}
                />
            }
            <SttHelpdeskShortcut
                config={global.gConfig}
                usuario={user}
            />
        </div>
    );
};

export default Menu;
