import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import SolicitacoesEmAberto from '../../componentes/envio-imagens/grid-solicitacoes';

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(3)
    }
}));

const Solicitacoes = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <SolicitacoesEmAberto />
        </div>
    );
};

export default Solicitacoes;