export const initialValues = {
    paciente: {
        id: '',
        cpf: '',
        cns: '',
        nome: '',
        genero: null,
        dataNascimento: null,
        municipio: null,
        uf: null,
        pais: null,
        peso: '',
        altura: '',
        cep: '',
        logradouro: '',
        bairro: '',
        tipoContato1: null,
        contato1: ''
    },
    solicitante: {
        medico: null,
        outroMedico: '',
        instituicao: null
    },
    informacoesClinicas: {
        motivoExame: '',
        antecedentes: [],
        antecedentesOutros: '',
        indicacaoExame: '',
        historiaClinica: '',
        medicamentosUso: '',
        resultadoExames: '',
    }
};