export const modeloPadrao = [
    {
        secao: 'Descrição do estudo',
        conteudo: ''
    },
    {
        secao: 'Achados',
        conteudo: ''
    },
    {
        secao: 'Conclusão',
        conteudo: ''
    }
];