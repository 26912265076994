import { Field } from 'formik';
import * as yup from 'yup'
import { FIELDS } from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [FIELDS.INFORMACOES_CLINICAS]: yup.object().shape({
            [FIELDS.ANTECEDENTES]: yup.array().of(
                yup.object().shape({
                    id: yup.number(),
                    descricao: yup.string(),
                    complemento: yup.boolean()
                }).required(strings.campoObrigatorio)
            )
                .min(1, strings.antecedenteObrigatorio)
                .required(strings.campoObrigatorio),
            [FIELDS.ANTECEDENTES_OUTROS]: yup
                .string()
                .when(FIELDS.ANTECEDENTES, {
                    is: (val) => {
                        return val && val.some(v => v.complemento)
                    },
                    then: yup
                        .string()
                        .nullable()
                        .required(strings.campoObrigatorio)
                }).nullable(),
        }).required()
    });
};