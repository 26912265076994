import {
    DECS,
    ID_EXAME,
    ID_LAUDO_EMISSAO,
    TIMESTAMP_INICIO,
    ID_PACIENTE,
    ID_MEDICO_SOLICITANTE,
    NOME_MEDICO_SOLICITANTE,
    CONTEUDO_TEXTUAL,
} from './fieldNames';
import { CID_10 } from '@stt-componentes/cid10/dist/lib/fieldNames';

export const values = (idExame, idLaudoEmissao, idPaciente, idSolicitante, nomeSolicitante, modelo) => {
    let val = {
        [ID_EXAME]: idExame,
        [ID_LAUDO_EMISSAO]: idLaudoEmissao,
        [ID_PACIENTE]: idPaciente,
        [ID_MEDICO_SOLICITANTE]: idSolicitante,
        [NOME_MEDICO_SOLICITANTE]: nomeSolicitante,
        [TIMESTAMP_INICIO]: new Date().getTime(),
        [CID_10]: [],
        [DECS]: [],
        [CONTEUDO_TEXTUAL]: {}
    };

    modelo.forEach(campo => {
        val[CONTEUDO_TEXTUAL][campo.secao] = campo.conteudo || '';
    });

    return val;
}