import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
    isAuthenticated: false,
    user: null,
    examesSemLaudo: null,
};

export const indexSlice = createSlice({
    name: 'index',
    initialState: INITIAL_STATE,
    reducers: {
        setAuthenticated: (state) => {
            return {
                ...state,
                isAuthenticated: true
            };
        },
        setUser: (state, action) => {
            return {
                ...state,
                user: action.payload
            };
        },
        setExamesSemLaudo: (state, action) => {
            return {
                ...state,
                examesSemLaudo: action.payload
            };
        },
    }
});

export const { setAuthenticated, setUser, setExamesSemLaudo } = indexSlice.actions;

export default indexSlice.reducer;
