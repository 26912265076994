import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = { laudar: false };

export const laudoSlice = createSlice({
    name: 'laudo',
    initialState: INITIAL_STATE,
    reducers: {
        setLaudar: (state, action) => {
            return { laudar: action.payload }
        }
    }
});

export const { setLaudar } = laudoSlice.actions;

export default laudoSlice.reducer;
