export const TIPO_ABA_EXAME = {
    INSTITUICAO: 'INSTITUICAO',
    REDE: 'REDE',
    PERFIL: 'PERFIL'
}

export const VINCULO_PERFIL = {
    GLOBAL: 'G',
    INSTITUICAO: 'I',
    USUARIO: 'U',
    REDE: 'R'
}

export const SITUACAO_LAUDO = {
    COM_LAUDO: 'S',
    SEM_LAUDO: 'N',
    LAUDO_TEMPORARIO: 'T',
    LAUDO_EMISSAO: 'E',
    LAUDO_AGUARDANDO_COMPLEMENTO: 'A',
    DIGITADO: 'D',
    COM_LAUDO_APLICATIVO: 'M',
    AGUARDANDO_TRANSCRICAO: 'I'
}

export const SITUACAO = {
    VALIDO: 'V',
    INVALIDO: 'I'
}