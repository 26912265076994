import { createSlice } from '@reduxjs/toolkit';
import { estadoInicialForm } from '../componentes/exame/estadoInicialForm';

const filtrosIniciais = estadoInicialForm();

const INITIAL_STATE = {
    filtros: filtrosIniciais,
    listar: false
};

export const exameSlice = createSlice({
    name: 'exame',
    initialState: INITIAL_STATE,
    reducers: {
        setFiltros: (state, action) => {
            return {
                ...state,
                filtros: action.payload
            }
        },
        resetFiltros: (state) => {
            return {
                ...state,
                filtros: filtrosIniciais
            };
        },
        atualizarBusca: (state, action) => {
            return {
                ...state,
                listar: action.payload
            }
        }
    }
});

export const { setFiltros, resetFiltros, atualizarBusca } = exameSlice.actions;

export default exameSlice.reducer;
