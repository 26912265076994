import * as yup from 'yup';

export default (strings) => {
    let schema = yup.object().shape({
        // [DESCRICAO_ESTUDO]: yup
        //     .string()
        //     .trim()
        //     .nullable(),
        // [ACHADOS]: yup
        //     .string()
        //     .trim()
        //     .nullable(),
        // [CONCLUSAO]: yup
        //     .string()
        //     .trim()
        //     .nullable(),
    });

    return schema;
}