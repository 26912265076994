import React, { useState, lazy, Suspense, memo, useContext } from 'react';
import {
    SttTabs,
    SttCircularProgress,
    SttTranslateHook
} from '@stt-componentes/core';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(1.5)
    },
}));

const ANTECEDENTES = lazy(() => import('../../componentes/administrativo/antecedentes/pesquisa'));

const AntecedenteSuspense = memo((props) => {
    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <ANTECEDENTES {...props} />
        </Suspense>
    )
});

const IndexAdministrativo = () => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const [abas] = useState([{
        titulo: strings.antecedentes,
        conteudo: AntecedenteSuspense,
        key: 'AntecedenteSuspense',
        permanente: true
    }]);

    const [abaAtiva, setAbaAtiva] = useState('0');

    return (
        <SttTabs abas={abas}
            className={classes.wrapper}
            handleChangeAbaAtiva={setAbaAtiva}
            abaAtiva={abaAtiva}
            permanente={true}
            preload={false}
        />
    );
};

export default IndexAdministrativo;