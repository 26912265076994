import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
    filtros: null,
};

export const pesquisaSlice = createSlice({
    name: 'pesquisa',
    initialState: INITIAL_STATE,
    reducers: {
        setFiltrosPesquisa: (state, action) => {
            return {
                ...state,
                filtros: action.payload
            };
        },
    }
});

export const { setFiltrosPesquisa } = pesquisaSlice.actions;

export default pesquisaSlice.reducer;