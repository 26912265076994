import React, { useState, useRef, useContext, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Field, Formik } from 'formik';
import axios from 'axios';
import { getHeaders } from '../../request';
import HttpStatus from 'http-status-codes';
import validationSchema from './validationSchema';
import {
    SttGrid,
    SttButton,
    SttTranslateHook,
    SttLoading,
    SttExpansionPanel,
    SttDivider,
    SttAutocomplete,
    SttFormLabel,
    SttFormHelperText
} from '@stt-componentes/core';
import { SttCkEditor } from '@stt-componentes/ckeditor'
import { values } from './initialValues';
import { useLocation } from 'react-router-dom';
import SttCid10 from '@stt-componentes/cid10';
import SttDecs from '@stt-componentes/decs';
import ModalConfirmacao from './modalConfirmacao';
import { useDispatch, useSelector } from 'react-redux';
import { configure, open } from '../../reducers/alerta';
import { atualizarBusca } from '../../reducers/exame';
import { useSignal, useSignalEffect, useSignals } from '@preact/signals-react/runtime';
import { modeloPadrao } from './modeloLaudo';
import { CONTEUDO_TEXTUAL } from './fieldNames';

const useStyles = makeStyles(theme => ({
    conteinerDecs: {
        paddingTop: theme.spacing(2)
    },
    divider: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5)
    }
}));

const Form = (props) => {
    const {
        idExame,
        idLaudoEmissao,
        idPaciente,
        idSolicitante,
        nomeSolicitante,
        setLaudar,
        callbackProximoExame
    } = props;
    useSignals()

    const location = useLocation();
    const classes = useStyles();
    const dispatch = useDispatch();

    const user = useSelector(state => state.index.user);
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const schema = validationSchema(strings);
    const laudoTextualRef = useRef();

    // Controles do modal de confirmação
    const [modalAberto, setModalAberto] = useState(false);
    const [laudo, setLaudo] = useState(null);
    const modelosUsuario = useSignal([]);
    const modeloLaudo = useSignal(modeloPadrao);
    const modeloUsuario = useSignal(null);
    const initialValues = useSignal(values(idExame, idLaudoEmissao, idPaciente, idSolicitante, nomeSolicitante, modeloPadrao));

    const [contextoFabricalaudo] = useState(location.pathname === '/laudo');

    useEffect(() => {
        axios.get(`${global.gConfig.url_base_eeg}/laudo/modelo/funcionario/${user.idFuncionario}`, { headers: getHeaders() })
            .then((response) => {
                if (response) {
                    modelosUsuario.value = response.data;
                }
            })
            .catch(err => console.log(err));
    }, []);

    useSignalEffect(() => {
        initialValues.value = values(idExame, idLaudoEmissao, idPaciente, idSolicitante, nomeSolicitante, modeloLaudo.value);
    });

    const callbackCancelar = () => {
        setModalAberto(false);
    }

    const callbackConfirmar = () => {
        setModalAberto(false);
        if (contextoFabricalaudo) {
            callbackProximoExame();
        } else {
            setLaudar(false);
            dispatch(atualizarBusca(true));
        }
    }

    const handleChangeModelo = (event, modelo) => {
        if (!modelo) {
            modeloUsuario.value = null;
            modeloLaudo.value = modeloPadrao;
            return;
        }
        modeloUsuario.value = modelo;
        axios.get(`${global.gConfig.url_base_eeg}/laudo/modelo/${modelo.id}/funcionario`, { headers: getHeaders() })
            .then((response) => {
                if (response) {
                    const dados = response.data;
                    modeloLaudo.value = dados;
                }
            })
            .catch(err => {
                modeloLaudo.value = modeloPadrao;
            });
    }

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues.value}
            validationSchema={schema}
            onSubmit={(data, { setSubmitting }) => {
                axios.post(`${global.gConfig.url_base_eeg}/laudo/resumo`, data, { headers: getHeaders() })
                    .then((response) => {
                        setLaudo(response.data.data);
                        setModalAberto(true);
                    })
                    .catch(err => {
                        console.log(err);
                        const { response } = err;
                        let msg = strings.mensagemErroGeral;

                        const alertConfig = {
                            title: strings.erro,
                            message: msg,
                            type: 'error',
                            open: true,
                            options: [
                                {
                                    title: strings.ok,
                                    onClick: () => {
                                        dispatch(open(false));
                                    }
                                }
                            ],
                            onClose: () => {
                                dispatch(open(false));
                            }
                        };
                        if (response && (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.INTERNAL_SERVER_ERROR)) {
                            let arrMensagem = [];
                            response.data.errors.forEach(error => {
                                arrMensagem.push(`- ${error.message}`);
                            });
                            msg = arrMensagem.join('\n');
                            alertConfig.titulo = response.data.message;
                            alertConfig.message = msg;
                        }
                        dispatch(configure(alertConfig));
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }}
        >
            {
                ({
                    values,
                    errors,
                    isSubmitting,
                    submitCount,
                    setFieldValue,
                    handleSubmit,
                }) => {
                    return (
                        <>
                            <form onSubmit={handleSubmit} noValidate>
                                <SttExpansionPanel
                                    title={strings.laudoTextual}
                                    opened={false}
                                    compact
                                    children={
                                        <>
                                            <div ref={laudoTextualRef}></div>
                                            <SttGrid container spacing={1}>
                                                {
                                                    modelosUsuario.value.length > 0 &&
                                                    <SttGrid item xs={12}>
                                                        <SttAutocomplete
                                                            inputprops={{
                                                                name: 'modeloUsuario',
                                                                label: strings.modelosLaudoUsuario
                                                            }}
                                                            getOptionLabel={option => option?.descricao || ''}
                                                            getOptionSelected={(option, val) => option?.id === val?.id}
                                                            options={modelosUsuario.value}
                                                            value={modeloUsuario.value}
                                                            onChange={handleChangeModelo}
                                                        />
                                                    </SttGrid>
                                                }
                                                {
                                                    modeloLaudo.value.map((modelo, idx) => (
                                                        <SttGrid item xs={12} key={idx}>
                                                            <Field name={`${CONTEUDO_TEXTUAL}.${modelo.secao}`}>
                                                                {({
                                                                    field: { value, name }, meta
                                                                }) => (
                                                                    <>
                                                                        <SttFormLabel component="legend" className={classes.ckeditorLabel} error={(meta.touched && meta.error) ? true : false}>
                                                                            {modelo.secao}
                                                                        </SttFormLabel>
                                                                        <SttCkEditor
                                                                            height={300}
                                                                            id={modelo.id}
                                                                            data={value || modelo.conteudo}
                                                                            name={name}
                                                                            onChange={(event, editor) => {
                                                                                let data = editor.getData();
                                                                                setFieldValue(name, data);
                                                                            }}
                                                                            radiologia={false}
                                                                        />
                                                                        <SttFormHelperText error={true}>{meta.touched && meta.error ? meta.error : undefined}</SttFormHelperText>
                                                                    </>
                                                                )}
                                                            </Field>
                                                        </SttGrid>
                                                    ))
                                                }
                                            </SttGrid>
                                        </>
                                    }
                                />

                                <SttDivider className={classes.divider} />

                                <SttExpansionPanel
                                    title={strings.descritores}
                                    compact
                                    opened={false}
                                    children={
                                        <SttGrid container spacing={3}>
                                            <SttGrid item xs={12}>
                                                <div className={classes.conteinerDecs}>
                                                    <SttCid10
                                                        strings={strings}
                                                        headers={getHeaders()}
                                                        multiplos
                                                        formExterno={{
                                                            cid10: values.cid10,
                                                            isSubmitting,
                                                            errors,
                                                            submitCount,
                                                            setFieldValue
                                                        }}
                                                    />
                                                </div>
                                            </SttGrid>
                                            <SttGrid item xs={12}>
                                                <div className={classes.conteinerDecs}>
                                                    <SttDecs
                                                        strings={strings}
                                                        multiplos
                                                        formExterno={{
                                                            decs: values.decs,
                                                            isSubmitting,
                                                            errors,
                                                            submitCount,
                                                            setFieldValue
                                                        }}
                                                    />
                                                </div>
                                            </SttGrid>
                                        </SttGrid>
                                    }
                                />

                                <SttDivider className={classes.divider} />

                                <SttGrid container spacing={3}>
                                    <SttGrid item xs={12}>
                                        <SttButton
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={isSubmitting}
                                            nomarginleft="true"
                                            onClick={() => {
                                                const erros = Object.keys(errors).sort();
                                                if (erros.length > 0) {
                                                    setTimeout(() => {
                                                        laudoTextualRef.current.scrollIntoView({
                                                            behavior: 'smooth',
                                                            block: 'center',
                                                            inline: 'start'
                                                        });
                                                    }, 300);
                                                }

                                            }}
                                        >
                                            {strings.publicar}
                                        </SttButton>
                                    </SttGrid>
                                </SttGrid>
                            </form>
                            <SttLoading
                                open={isSubmitting}
                                text={strings.gerandoResumo}
                            />
                            <ModalConfirmacao
                                html={laudo}
                                modalAberto={modalAberto}
                                idExame={idExame}
                                callbackConfirmar={callbackConfirmar}
                                callbackCancelar={callbackCancelar}
                            />
                        </>
                    )
                }
            }
        </Formik>
    );
};

export default Form;