import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    SttNotification,
    SttAlertTitle,
    SttLink,
    SttTranslateHook
} from '@stt-componentes/core';
import { getHeaders } from '../../request'
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    notification: {
        display: 'flex',
        flexDirection: 'column'
    },
}));

const ListaAnexos = ({ anexos }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    const baixarAnexo = (anexo) => {
        axios.get(anexo.url, { responseType: 'blob' })
            .then((response) => {
                console.log(response);
                const fileURL = URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = fileURL;
                link.setAttribute('download', anexo.nome_arquivo);
                document.body.appendChild(link);
                link.click();
            })
            .catch(err => {
                console.log(err);
            });
    };

    return (
        <>
            <SttNotification severity="success" icon={false}>
                <SttAlertTitle>{strings.anexos}</SttAlertTitle>
                <div className={classes.notification}>
                    {
                        anexos.map((anexo, index) => {
                            return <SttLink key={index} style={{ wordBreak: 'break-all', cursor: 'pointer' }} onClick={() => baixarAnexo(anexo)} target={`_blank`}>{anexo.nome_arquivo}</SttLink>
                        })
                    }
                </div>
            </SttNotification>
        </>
    );
};

export default ListaAnexos;