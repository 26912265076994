import React from 'react';
import { temPerfilRBAC, temPermissaoRBAC } from '../../secutity/acl';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, permissao, perfil }) => {
    const user = useSelector(state => state.index.user);

    if (permissao) {
        return temPermissaoRBAC(user, permissao) ? <Component /> : <Navigate replace to='/nao-autorizado' />
    }
    let temPermissao = false;
    if (Array.isArray(perfil)) {
        perfil.forEach(p => {
            if (temPerfilRBAC(user, p)) {
                temPermissao = true;
            }
        });
        return temPermissao ? <Component /> : <Navigate replace to='/nao-autorizado' />
    }
    return temPerfilRBAC(user, perfil) ? <Component /> : <Navigate replace to='/nao-autorizado' />
};

export default ProtectedRoute;